<template>
  <div
    class="relative flex justify-center items-center gap-2.5 rounded-full w-10 h-10 bg-flush-orange flex-none"
  >
    <icon-base :icon="IconDealNotes" class="text-white" />
    <div
      v-if="noteType && noteIcons[noteType].icon"
      class="flex p-1 rounded-full border-2 border-divider w-6 h-6 absolute -right-[9px] -bottom-[9px]"
      :class="noteIcons[noteType].icon?.class || null"
    >
      <icon-base
        :icon="noteIcons[noteType].icon?.component"
        width="12"
        height="12"
        :view-box="noteIcons[noteType].icon?.viewBox"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { Note } from "@/models/notes";
import { NotableType } from "@/enums/notes";
import IconDealNotes from "@/components/icons/dealNotes/DealNotes.vue";
import IconBusiness from "@/components/icons/IconBusiness.vue";
import IconTelephone from "@/components/icons/IconTelephone.vue";

const props = defineProps<{
  note: Note;
}>();

const noteIcons = {
  application: {
    icon: undefined
  },
  business: {
    icon: {
      component: IconBusiness,
      viewBox: "0 0 16 16",
      class: "bg-secondary-purple text-white"
    }
  },
  call: {
    icon: {
      component: IconTelephone,
      viewBox: "0 0 16 16",
      class: "bg-secondary-green text-white"
    }
  }
};

const noteType = ref<NotableType | null>(
  props.note.notable_type ? NotableType[props.note.notable_type] : null
);
</script>
